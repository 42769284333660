import styled, { css } from 'styled-components';

import Button from '~/src/common/components/Button';
import {
  DeliveryInfoButton,
  DeliveryInfoIcon,
  DeliveryInfoText,
} from '~/src/common/components/DeliveryInfoPanel/layout';
import Icon from '~/src/common/components/Icon';
import Link from '~/src/common/components/Link';
import { FAMILY_MENU_HEIGHT } from '~/src/common/components/Navigation/constants';
import { H3 } from '~/src/common/components/Typography';
import { NAV_HEIGHT, media } from '~/src/styles-constants';

export const GRID_AREA_SLIDE = 'slide';
export const GRID_AREA_BREADCRUMBS = 'breadcrumbs';

export const Content = styled.div`
  position: relative;
  max-width: 1200px;
  padding: 0;
  margin: 0 -11px; /* Du fait du grid-gap */

  /* Le header à une margin bottom de 8px commune sur toute les pages hormis la page produit/recette */
  margin-top: -8px;

  ${media.M`
    margin: auto;
  `}
`;

export const GridContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;

  ${media.M`
    grid-gap: 32px;
    grid-template-columns: 1fr 450px;
  `}
`;

export const ImageSliderContainer = styled.div`
  height: 100%;
  width: 100%;
  max-height: 100%;
  margin-left: auto;
  margin-right: auto;
  grid-area: ${GRID_AREA_SLIDE};

  ${media.M`
    grid-column: 1;
    grid-row: 2;
    max-height: 100%;
  `}
`;

export const NavigationContainer = styled.div`
  display: none;

  grid-area: ${GRID_AREA_BREADCRUMBS};

  ${media.M`
    display: flex;
    align-items: center;
    grid-column: 1;
    grid-row: 1;
    margin-bottom: -26px;  // Du fait du grid-gap
    margin-top: 8px;
  `}

  ${media.XL`
    margin-top: 16px;
  `}
`;

export const DesktopGoBackButton = styled(Button)`
  height: auto;
  min-height: auto;
  margin: 0;

  span {
    padding: 0 !important;
    display: flex;
    align-items: center;
    text-decoration: none;
  }
`;

export const GoBackSpan = styled.span`
  margin-left: 6px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
`;

const breadcrumbsStyle = css`
  & span {
    font-size: 12px;
    line-height: 16px;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  & > span:last-child {
    color: ${({ theme }) => theme.palette.common.DISABLED};
  }

  ${Icon} {
    position: relative;
    font-size: 16px;
  }
`;

export const DesktopBreadcrumbsContainer = styled.div`
  ${breadcrumbsStyle};
  display: none;

  ${media.M`
    display: flex;
    align-items: center;
    margin-left: 16px;
  `}
`;

export const MobileBreadcrumbsContainer = styled.div`
  ${breadcrumbsStyle};
  display: flex;
  align-items: center;

  overflow: auto;
  white-space: nowrap;
  padding: 0 16px 8px;

  /* Chrome & Safari */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Firefox */
  scrollbar-width: none;

  ${media.M`
    display: none;
  `}
`;

export const BreadcrumbLink = styled(Link)`
  display: flex;
  align-items: center;

  font-size: 12px;
  line-height: 16px;

  &,
  &:hover {
    color: inherit;
  }
`;

export const ContentNotFound = styled(H3)`
  margin: 50px auto;
  text-align: center;

  grid-column: 1 / 3;
`;

export const StickyDiv = styled.div<{ $hasTag: boolean }>`
  ${media.M<{ $hasTag: boolean }>`
    top: ${({ $hasTag }) => ($hasTag ? 80 : 66)}px;
    position: sticky;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 400px;
    max-width: 450px;
  `}

  ${media.XL`
    top: ${NAV_HEIGHT + FAMILY_MENU_HEIGHT + 38}px; // 38px correspond à la hauteur du breadcrumb
  `}
`;

export const StickyInnerContainer = styled.div`
  position: relative;
`;

export const StickyDivBaseContainer = styled.div`
  padding: 0 16px;
  ${media.M`
    padding: 0 ;
  `}
`;

export const StickyDivTopContainer = styled(StickyDivBaseContainer)`
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const TagsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const CatalogItemNameContainer = styled.div`
  align-items: center;
  width: 100%;
  margin-bottom: 24px;
  margin-top: 8px;
`;

export const CatalogItemName = styled.h1`
  font-size: 24px;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-weight: bold;
  color: ${({ theme }) => theme.palette.common.TEXTS};
  line-height: 32px;
  margin-bottom: 24px;
  margin-top: 0;
`;

export const tagStyle = css`
  padding: 0 4px;
  white-space: pre;

  font-weight: 500;
  font-size: 12px;
  line-height: 24px;

  border-radius: 4px;

  &:not(:last-child) {
    margin-right: 10px;
  }
`;

export const LightGreenTag = styled.div`
  display: inline-flex;
  align-items: center;
  ${tagStyle};
  margin-bottom: 8px;

  background-color: ${({ theme }) => theme.palette.common.LIGHT_GREEN};
  color: ${({ theme }) => theme.palette.common.PRIMARY};

  & ${Icon} {
    margin-right: 4px;
    line-height: 24px;
  }
`;

export const DeliveryInfoLayout = styled.div<{ $isOpen: boolean }>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  max-height: ${({ $isOpen }) => ($isOpen ? '320px' : 0)};
  ${media.S<{ $isOpen: boolean }>`
    max-height: ${({ $isOpen }) => ($isOpen ? '320px' : 0)};
  `}

  z-index: 10;
  overflow: hidden;

  background-color: ${({ theme }) => theme.palette.common.PRIMARY};
  color: ${({ theme }) => theme.palette.common.WHITE};
  border-radius: 10px;

  transition: max-height 0.15s ease-out;

  ${DeliveryInfoIcon} {
    font-size: 55px;
  }

  ${DeliveryInfoButton} {
    padding: 10px 24px;
    margin-bottom: 16px;
  }

  ${DeliveryInfoText} {
    margin-bottom: 16px;
  }
`;

export const DeliveryInfoContainer = styled.div`
  padding: 16px;
  display: flex;
  justify-content: center;
  height: 100%;

  > div:first-child > h3 {
    font-size: 20px;
    line-height: 24px;
  }

  > div:nth-child(3) {
    padding: 15px;

    span {
      font-size: 15px;
    }
  }

  > div:first-child > div {
    font-size: 16px;
    line-height: 1.43;
  }

  > div > button:nth-of-type(1) {
    span {
      font-size: 16px;
      line-height: 20px;
    }
  }

  > div > button:last-child {
    span {
      font-size: 14px;
      line-height: 20px;
    }
  }

  ${media.S`
    > div:first-child > div {
      font-size: 16px;
    }
  `}
`;
