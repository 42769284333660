import { PageType } from './types';

/** Le type de page correspondant a chacune des routes existante */
export const PageTypes: Partial<Record<string, PageType>> = {
  '/': 'Home',
  '/404': 'Brand',
  '/[slug]': 'Root',
  '/account/addresses': 'User Portal',
  '/account/addresses/add': 'User Portal',
  '/account/addresses/edit/[addressId]': 'User Portal',
  '/account/me': 'User Portal',
  '/account/orders': 'User Portal',
  '/account/orders/[orderId]': 'User Portal',
  '/account/paiement': 'User Portal',
  '/account/preferences': 'User Portal',
  '/account/referral': 'User Portal',
  '/account/v2': 'User Portal',
  '/account/v2/benefits': 'User Portal',
  '/account/v2/benefits/coupons': 'User Portal',
  '/account/v2/benefits/referral': 'User Portal',
  '/account/v2/orders': 'User Portal',
  '/account/v2/orders/[orderId]': 'User Portal',
  '/account/v2/payments': 'User Portal',
  '/account/v2/referral': 'User Portal',
  '/account/v2/settings': 'User Portal',
  '/account/v2/settings/addresses': 'User Portal',
  '/account/v2/settings/addresses/edit': 'User Portal',
  '/account/v2/settings/communications': 'User Portal',
  '/account/v2/settings/me': 'User Portal',
  '/account/v2/settings/payment': 'User Portal',
  '/account/v2/settings/reset-password': 'User Portal',
  '/categorie/[categorySlug]': 'Category',
  '/cgv': 'Brand',
  '/checkout': 'Checkout',
  '/engagement': 'Brand',
  '/engagement/fromagerie': 'Brand',
  '/engagement/primeur': 'Brand',
  '/engagement/traiteur': 'Brand',
  '/entreprise/[slug]': 'Brand',
  '/info/[slug]': 'Brand',
  '/landing': 'Landing',
  '/landing/SRP23': 'Landing',
  '/landing/v2': 'Landing',
  '/landing/v3': 'Landing',
  '/landing/v4': 'Landing',
  '/navigateur-obsolete': 'Brand',
  '/offre/[slug]': 'Brand',
  '/produit/[slug]': 'Product',
  '/recette/[slug]': 'Recipe',
  '/recherche': 'Search',
  '/reset-password': 'Home',
};
