import BookmarkProductButton from '~/src/common/components/BookmarkProductButton/bookmark-product-button.fr';
import deliveryInfoPanel from '~/src/common/components/DeliveryInfoPanel/delivery-info-panel.fr';
import deliveryProgressBar from '~/src/common/components/DeliveryProgressBar/delivery-progress-bar.fr';
import deliverySlots from '~/src/common/components/DeliverySlots/delivery-slots.fr';
import errorBoundary from '~/src/common/components/ErrorBoundary/error-boundary.fr';
import validation from '~/src/common/components/Form/validation.fr';
import googlePlacesAutocomplete from '~/src/common/components/GooglePlacesAutocomplete/google-places-autocomplete.fr';
import productCard from '~/src/common/components/GridCard/ProductCard/product-card.fr';
import recipeCard from '~/src/common/components/GridCard/RecipeCard/recipe-card.fr';
import accountScreen from '~/src/common/components/Layout/account-screen.fr';
import accountNav from '~/src/common/components/Layout/AccountNav/account-nav.fr';
import legalInfo from '~/src/common/components/LegalInfo/legal-info.fr';
import passwordRules from '~/src/common/components/PasswordRules/password-rules.fr';
import pickerDebounced from '~/src/common/components/PickerDebounced/picker-debounced.fr';
import productListItem from '~/src/common/components/ProductListItem/product-list-item.fr';
import productsGrid from '~/src/common/components/ProductsGrid/products-grid.fr';
import prospectEmailForm from '~/src/common/components/ProspectEmailForm/prospect-email-form.fr';
import readmore from '~/src/common/components/ReadMore/read-more.fr';
import search from '~/src/common/components/Search/search.fr';
import serverError from '~/src/common/components/ServerError/server-error.fr';
import signinContent from '~/src/common/components/SigninContent/signin-content.fr';
import smallHeader from '~/src/common/components/SmallHeader/small-header.fr';
import errors from '~/src/common/services/error-handler/errors.fr';
import toaster from '~/src/common/services/Toaster/toaster.fr';
import price from '~/src/common/utils/price.fr';
import accountAddressModal from '~/src/screens/Account/AccountAddresses/AddAccountAddressesScreen/AccountAddressModal/account-address-modal.fr';
import addAccountAddressesScreen from '~/src/screens/Account/AccountAddresses/AddAccountAddressesScreen/add-account-addresses-screen.fr';
import listAccountAddressesScreen from '~/src/screens/Account/AccountAddresses/ListAccountAddressesScreen/list-account-addresses-screen.fr';
import benefitsScreen from '~/src/screens/Account/Benefits/benefits-screen.fr';
import creditCards from '~/src/screens/Account/EditAccountCreditCardsScreen/AccountCreditCardsList/credit-cards.fr';
import editAccountReferralScreen from '~/src/screens/Account/EditAccountReferralScreenOld/edit-account-referral-screen.fr';
import editAccountScreen from '~/src/screens/Account/EditAccountScreen/edit-account-screen.fr';
import editAccountSubscriptionsEditScreen from '~/src/screens/Account/EditAccountSubscriptionsScreen/edit-account-subscriptions-screen.fr';
import myAccountScreen from '~/src/screens/Account/my-account-screen.fr';
import orderAgainProductsModal from '~/src/screens/Account/Orders/OrderAgainProductsModal/order-again-products-modal.fr';
import orderScreen from '~/src/screens/Account/Orders/OrderScreen/order-screen.fr';
import accountOrdersScreen from '~/src/screens/Account/Orders/OrdersScreen/account-orders-screen.fr';
import selectSlotModal from '~/src/screens/Account/Orders/SelectSlotModal/select-slot-modal.fr';
import orderItem from '~/src/screens/Account/OrdersScreenOld/OrderItem/order-item.fr';
import orders from '~/src/screens/Account/OrdersScreenOld/OrderProgressStatus/orders.fr';
import ordersScreen from '~/src/screens/Account/OrdersScreenOld/orders-screen.fr';
import weightExplanationPanel from '~/src/screens/Account/OrdersScreenOld/WeightExplanation/weight-explanation.fr';
import addressesScreen from '~/src/screens/Account/Settings/Addresses/AddressesScreen/addresses-screen.fr';
import editAddressScreen from '~/src/screens/Account/Settings/Addresses/EditAddressScreen/edit-address-screen.fr';
import selectAddressModal from '~/src/screens/Account/Settings/Addresses/EditAddressScreen/SelectAddressModal/select-address-modal.fr';
import communicationsScreen from '~/src/screens/Account/Settings/CommunicationsScreen/communications-screen.fr';
import paymentScreen from '~/src/screens/Account/Settings/PaymentScreen/payment-screen.fr';
import personalInformationScreen from '~/src/screens/Account/Settings/PersonalInformationScreen/personal-information-screen.fr';
import resetPasswordScreen from '~/src/screens/Account/Settings/ResetPasswordScreen/reset-password-screen.fr';
import settingsScreen from '~/src/screens/Account/Settings/settings-screen.fr';
import accountManagementModal from '~/src/screens/AccountManagementModal/account-management-modal.fr';
import footer from '~/src/screens/App/Footer/footer.fr';
import navigation from '~/src/screens/App/Header/Navigation/navigation.fr';
import cartScreen from '~/src/screens/CartScreen/cart-screen.fr';
import lastMinutePurchasePanel from '~/src/screens/CartScreen/LastMinutePurchasePanel/last-minute-purchase-panel.fr';
import updatedProductsPanel from '~/src/screens/CartScreen/UpdatedProductsPanel/updated-products-panel.fr';
import productScreen from '~/src/screens/CatalogContent/ContentScreen/ProductScreen/product-screen.fr';
import recipeScreen from '~/src/screens/CatalogContent/ContentScreen/RecipeScreen/recipe-screen.fr';
import listProductScreen from '~/src/screens/CatalogContent/ListProductsScreen/list-products-screen.fr';
import checkoutScreen from '~/src/screens/Checkout/checkout-screen.fr';
import CheckoutConfirmationModal from '~/src/screens/Checkout/CheckoutConfirmationModal/checkout-confirmation-modal.fr';
import paymentTypes from '~/src/screens/Checkout/PaymentTypesList/payment-types.fr';
import ReplaceMissingProductsModal from '~/src/screens/Checkout/ReplaceMissingProductsSection/ReplaceMissingProductsModal/replace-missing-products-modal.fr';
import commitments from '~/src/screens/Commitments/commitments.fr';
import couponConfirmationModal from '~/src/screens/CouponConfirmationModal/coupon-confirmation-modal.fr';
import deliveryModal from '~/src/screens/DeliveryModal/delivery-modal.fr';
import deliveryTrackingDrawer from '~/src/screens/DeliveryTrackingDrawer/delivery-tracking-drawer.fr';
import expiredDeliverySlotModal from '~/src/screens/ExpiredDeliverySlotModal/expired-delivery-slot.fr';
import googleDocScreen from '~/src/screens/GoogleDocScreen/google-doc-screen.fr';
import home from '~/src/screens/Home/home.fr';
import landing from '~/src/screens/Landing/landing.fr';
import outdatedBrowser from '~/src/screens/OutdatedBrowser/outdated-browser.fr';
import recipeIngredientsModal from '~/src/screens/RecipeIngredientsModal/recipe-ingredients-modal.fr';
import referralModal from '~/src/screens/ReferralModal/referral-modal.fr';
import resetPasswordModal from '~/src/screens/ResetPasswordModal/reset-password-modal.fr';
import searchProductsScreen from '~/src/screens/SearchProductsScreen/search-products-screen.fr';
import substituteProducts from '~/src/screens/SubstituteProducts/substitute-products.fr';
import userPersonalListsScreen from '~/src/screens/UserPersonalLists/user-personal-lists-screen.fr';
import weightExplanationModal from '~/src/screens/WeightPricingExplanationModal/weight-pricing-explanation-modal.fr';

import common from './fr/common';
import date from './fr/date';

const fr = {
  'server-error': serverError,
  'form': {
    validation,
  },
  'account': {
    nav: accountNav,
  },
  common,
  date,
  footer,
  home,
  'account-address-modal': accountAddressModal,
  'account-management-modal': accountManagementModal,
  'account-screen': accountScreen,
  'my-account': myAccountScreen,
  'account-orders-screen': accountOrdersScreen,
  'order-screen': orderScreen,
  'order-again-products-modal': orderAgainProductsModal,
  'benefits-screen': benefitsScreen,
  'settings-screen': settingsScreen,
  'addresses-screen': addressesScreen,
  'edit-address-screen': editAddressScreen,
  'select-address-modal': selectAddressModal,
  'personal-information-screen': personalInformationScreen,
  'reset-password-screen': resetPasswordScreen,
  'payment-screen': paymentScreen,
  'communications-screen': communicationsScreen,
  'add-account-addresses-screen': addAccountAddressesScreen,
  'bookmark-product-button': BookmarkProductButton,
  'cart-screen': cartScreen,
  'checkout-confirmation-modal': CheckoutConfirmationModal,
  'checkout-screen': checkoutScreen,
  'commitments': commitments,
  'coupon-confirmation-modal': couponConfirmationModal,
  'credit-cards': creditCards,
  'delivery-info-panel': deliveryInfoPanel,
  'delivery-modal': deliveryModal,
  'delivery-progress-bar': deliveryProgressBar,
  'delivery-tracking-drawer': deliveryTrackingDrawer,
  'edit-account-referral-screen': editAccountReferralScreen,
  'edit-account-screen': editAccountScreen,
  'edit-account-subscriptions-screen': editAccountSubscriptionsEditScreen,
  'error-boundary': errorBoundary,
  'errors': errors,
  'expired-delivery-slot-modal': expiredDeliverySlotModal,
  'google-places-autocomplete': googlePlacesAutocomplete,
  'last-minute-purchase-panel': lastMinutePurchasePanel,
  'list-account-addresses-screen': listAccountAddressesScreen,
  'list-products-screen': listProductScreen,
  'navigation': navigation,
  'order-item': orderItem,
  'orders-screen': ordersScreen,
  'orders': orders,
  'outdated-browser': outdatedBrowser,
  'password-rules': passwordRules,
  'payment-types': paymentTypes,
  'picker-debounced': pickerDebounced,
  'product-card': productCard,
  'product-list-item': productListItem,
  'products-grid': productsGrid,
  'prospect-email-form': prospectEmailForm,
  'read-more': readmore,
  'recipe-card': recipeCard,
  'recipe-ingredients-modal': recipeIngredientsModal,
  'recipe-screen': recipeScreen,
  'referral-modal': referralModal,
  'replace-missing-products-modal': ReplaceMissingProductsModal,
  'reset-password-modal': resetPasswordModal,
  'search-products-screen': searchProductsScreen,
  'search': search,
  'signin-content': signinContent,
  'small-header': smallHeader,
  'substitute-products-screen': substituteProducts,
  'toaster': toaster,
  'updated-products-panel': updatedProductsPanel,
  'user-personal-lists-screen': userPersonalListsScreen,
  'view-product-screen': productScreen,
  'weight-explanation-modal': weightExplanationModal,
  'weight-explanation-panel': weightExplanationPanel,
  landing,
  price,
  'select-slot-modal': selectSlotModal,
  'delivery-slots': deliverySlots,
  'legal-info': legalInfo,
  'google-doc-screen': googleDocScreen,
};

export default fr;
